import React, {useState,useEffect} from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube, faTwitter, faInstagram, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faArrowUp, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { toast, Toaster } from 'react-hot-toast';
import { SERVER_URL } from "../../config";

export default function Footer() {

    const [email, setEmails] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // console.log("emails",email)

    const handleSubmit = async (e) => {
        e.preventDefault();

        // console.log("emails",email);
        const loadingToastId = toast.loading('Subscribing...');

        try {
          
            const response = await axios.post(`${SERVER_URL}/api/newsletter_subscribe`, {
                email: email,
            });

      
         //   console.log("response",response);
         toast.dismiss(loadingToastId);
            setStatusMessage('Email successfully subscribed!');
            setErrorMessage('');
            toast.success('Email successfully subscribed!');
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
        
            toast.dismiss(loadingToastId);
            setStatusMessage('');
            setErrorMessage('Failed to subscribe email.');
            toast.error('Failed to subscribe email.');
        }
    };

    return (
        <div className='font-poppins'>
            <Toaster/>
            <div className='w-full bg-black '>
                <div className='container mx-auto '>
                    <div className='md:flex md:flex-row flex-col items-center justify-evenly lg:px-8 p-4'>
                        <div className='flex items-center justify-start  lg:w-1/3 md:w-1/5 w-full h-full md:border  md:border-r-white md:border-transparent border-none'>
                            <div className='my-4'>
                                <img src='/assets/footerLogo.png' className='w-[50%] mx-auto md:w-full brightness-150'></img>
                            </div>
                        </div>
                        <div className='lg:w-2/3 w-full flex sm:flex-row flex-col md:justify-between  md:ml-4 ml-0'>
                            <div className=' w-full block md:mt-0 mt-4 lg:text-start text-center'>
                                <ul className='flex justify-start flex-col'>
                                    <li><a href='#' className='text-white'><span className='text-white/20 pr-2'>|</span>Home</a></li>
                                    <li><a href='#' className='text-white'><span className='text-white/20 pr-2'>|</span>About us</a></li>
                                    <li><a href='#' className='text-white'><span className='text-white/20 pr-2'>|</span>MBC Exclusive</a></li>
                                </ul>
                            </div>
                            <div className=' w-full block  lg:text-start text-center  md:mt-0 mt-4'>
                                <ul className='flex justify-start flex-col'>
                                    <li><a href='#' className='text-white'><span className='text-white/20 pr-2'>|</span>Membership</a></li>
                                    <li><a href='#' className='text-white'><span className='text-white/20 pr-2'>|</span>Referrals</a></li>
                                    <li><a href='#' className='text-white'><span className='text-white/20 pr-2'>|</span>FAQ</a></li>
                                </ul>
                            </div>
                            <div className=' w-full block  lg:text-start text-center  md:mt-0 mt-4'>
                                <ul className='flex justify-start flex-col'>
                                    <li><a href='#' className='text-white'><span className='text-white/20 pr-2'>|</span>Dashboard</a></li>
                                </ul>
                            </div>
                            <div className=' w-full  lg:text-start text-center md:mt-0 mt-4'>
                                <h4 className='font-bold text-lg text-white mb-2'>Get in touch</h4>
                                <ul className='flex items-center gap-2 justify-between text-[#ee5d57] mt-3'>
                                    <li><a href='https://www.instagram.com/mybusinessclub_official/' className='px-3 py-2 border border-white/10 rounded-full'><FontAwesomeIcon icon={faInstagram} /></a></li>
                                    <li><a href='https://www.facebook.com/profile.php?id=61559105265026' className='px-3.5 py-2 border border-white/10 rounded-full'><FontAwesomeIcon icon={faFacebookF} /></a></li>
                                    <li><a href='https://rb.gy/a1nli1' className='px-3 py-2 border border-white/10 rounded-full'><FontAwesomeIcon icon={faTwitter} /></a></li>
                                    <li><a href='https://rb.gy/7x2p32' className='px-3 py-2 border border-white/10 rounded-full'><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                                    <li><a href='https://www.youtube.com/@MyBusinessClub_Official' className='px-2.5 py-2 border border-white/10 rounded-full'><FontAwesomeIcon icon={faYoutube} /></a></li>
                                </ul>
                                <form onSubmit={handleSubmit}>
                                    <div className='flex bg-white rounded-lg mt-3'>
                                        <input
                                            type='text'
                                            value={email}
                                            onChange={(e) => setEmails(e.target.value)}
                                            className='w-[90%] bg-transparent outline-none p-2 text-black text-xs placeholder:text-gray-500'
                                            placeholder='Enter your Email address'
                                        />
                                        <button type='submit' className='w-[10%] px-1'>
                                            <FontAwesomeIcon icon={faPaperPlane} className='text-[#ee5d57]' />
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-black border-t text-white border-white/20 px-[5%] py-5 flex flex-col md:flex-row gap-3 md:gap-0  justify-between'>
                <div className='my-auto'>© 2024 My Business Club</div>
                <div className='flex flex-col md:flex-row justify-end gap-3 md:gap-5'>
                    <p className='my-auto'>Terms of Service</p>
                    <p className='my-auto'>Privacy Policy</p>
                    {/* <p className='my-auto'>English <FontAwesomeIcon icon={faChevronDown} className='text-xs'/></p> */}
                    <div><a href='#' className='px-3.5 py-2 text-[#ee5d57] border border-white/10 rounded-full'><FontAwesomeIcon icon={faArrowUp} /></a></div>
                </div>
            </div>
        </div>
    )
}