import React, { useState } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { faChevronRight, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Aos from 'aos';
import 'aos/dist/aos.css';

Aos.init();

const FAQ = () => {
  const faq = [
    {
      question: "What is the difference between Plan A and Plan B?",
      answer:"Plan A: Basic Membership with an annual fee of $30, offering community building incentives and commissions up to 10 levels.<br />Plan B: Upgraded Membership with higher earning potentials, including upgrade commissions, rank incentives, and team matching bonuses up to 15 levels.",
    },
    {
      question: "How do I upgrade my membership?",
      answer:
        "To upgrade your membership, contact an MBC Partner or follow the upgrade link your member dashboard. Upgrading will unlock additional benefits and higher earning opportunities.",
    },
    {
      question: "What are the benefits of team matching bonuses?",
      answer:
        "Team matching bonuses allow you to earn a percentage of the incomes generated by your team members up to 15 levels deep. This encourages you to support and grow your team, maximizing collective earnings.a",
    },
    {
      question: "How does the upgrade commission work in Plan B?",
      answer:
        "You earn 10% on all Level 1 upgrades. For example, if a direct referral upgrades from a $100 membership to a $1000 membership, you earn 10% of the $900 difference, which is $90.",
    },
    {
      question: "What are the requirements for ranking up in Plan B?",
      answer:
        "To rank up, you need a minimum of 2 active legs, and no single leg should contribute more than 50% of your total target volume. Higher ranks offer increased override percentages, enhancing your earning potential.",
    },
    {
      question: "Can I earn commissions on referrals beyond Level 1 in Plan A?",
      answer:
        "Yes, Plan A allows you to earn commissions up to 10 levels deep, with different percentages for each level. The higher the level, the more you can earn as your team grows.",
    },
    {
      question: "What happens if I don’t upgrade my membership?",
      answer:
        "If you don’t upgrade, you will remain in Plan A and miss out on the additional benefits and higher earning potentials offered in Plan B, such as rank incentives and deeper team matching bonuses.",
    },
    {
      question: "How are commissions paid out?",
      answer:
        "Commissions are paid out instantly. For Plan A, 100% of the $30 membership fee is paid out as commissions for the first 90 days, and 80% from day 91 onwards. Plan B includes instant payouts for upgrade commissions and matching bonuses.",
    },
    {
      question: "How can I maximize my earnings with My Business Club?",
      answer:
        "To maximize your earnings, actively build and support your team, encourage upgrades to Plan B, and aim to rank up within the membership structure to unlock higher incentives and deeper matching bonuses.",
    },
  ];

  const [expandedIndex, setExpandedIndex] = useState(-1);

  const toggleExpand = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(-1); // Collapse if already expanded
    } else {
      setExpandedIndex(index); // Expand the selected item
    }
  };

  return (
    <div>
      <div
        className="  sec-1 bg-black bg-cover font-poppins text-white"
        style={{ backgroundImage: "url(../assets/NewMBCbg.png)" }}
      >
        <Header />

        <div className="pt-28">
          <h1 className="text-6xl 2xl:text-7xl text-center font-bold font-Artemus uppercase text-shadow-custom" data-aos="fade-up" data-aos-duration="">faq’s</h1>
          <p className="font-semibold text-lg text-center" data-aos="zoom-in" data-aos-duration="">Questions? Look here.</p>
        </div>

        <div className="md:w-[55%] w-[90%] mx-auto mt-10 pb-10" data-aos="fade-up" data-aos-duration="1000">
          {faq.slice(0, 9).map((data, index) => (
            <div
              key={index}
              onClick={() => toggleExpand(index)}
              className={`bg-white text-[#595959] cursor-pointer mt-2 duration-500 transform transition ease-in-out ${expandedIndex === index ? "h-full" : "h-full"
                }`}
            >
              <div className="flex ">
                <div className="w-[90%] p-3">
                  <h1 className="font-semibold py-2">{data.question}</h1>
                </div>
                <div className="w-[10%] flex items-center justify-center">
                  <button className="font-bold">
                    {expandedIndex === index ? (
                      <FontAwesomeIcon
                        icon={faMinus}
                        className={`transform transition-transform duration-500`}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faPlus}
                        className={`transform transition-transform duration-500`}
                      />
                    )}
                  </button>
                </div>
              </div>
              <div
                className={`duration-300 px-5 bg-gradient-to-r from-[#E43875] to-[#F98736] ease-in-out overflow-hidden ${expandedIndex === index
                  ? "max-h-[150px]  md:max-h-[120px] py-5"
                  : "max-h-0"
                  }`}
              >
                <p
                  dangerouslySetInnerHTML={{ __html: data.answer }}
                  className="text-sm text-white font-semibold duration-500"
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FAQ;
