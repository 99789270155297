import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Routers from './route';
import './fonts/Artemus.woff';
import './fonts/Artemus.ttf';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import ReactGA from "react-ga4";
import Intercom from '@intercom/messenger-js-sdk';
import { Provider } from 'react-redux';
import { store } from './store';

Intercom({
  app_id: 'xvklq4eq',
});

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = 'f5cf9cddd50eb4214c40f53f3e1652bb';

// 2. Set chains
const mainnet = {
  chainId: 42161,
  name: 'Arbitrum',
  currency: 'ETH',
  explorerUrl: 'https://arbiscan.io',
  rpcUrl: 'https://arb1.arbitrum.io/rpc'
};
const polygon = {
  chainId: 137,
  name: 'Polygon',
  currency: 'MATIC',
  explorerUrl: 'https://explorer-mainnet.maticvigil.com/',
  rpcUrl: 'https://polygon-mainnet.infura.io/v3/29l8LmTDhgubYwRqevSu29joCvA'
};

// 3. Create modal
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://skilleareum.ai',
  icons: ['https://skilleareum.ai/assets/logo.png']
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet, polygon],
  themeMode: 'light',
  projectId,
  enableEmail: true,
  defaultChain: mainnet,
  themeVariables: {
    '--w3m-accent': '#0db1a9',
  },
  enableAnalytics: true
});

// const TRACKING_ID = "G-RKMDER1ZTK";
const TRACKING_ID = "G-MYG08T2T0E";

ReactGA.initialize(TRACKING_ID);

const App = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Mybusinessclub"
    });
  }, []);

  return (
    <Routers />
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
