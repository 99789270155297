import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import Header from './Components/Layout/Header';
import Footer from './Components/Layout/Footer';
// import Login from './Components/Auth/Login';
// import Signup from './Components/Auth/Signup';
import Home from './Components/Pages/Home';
import About from './Components/Pages/About';
import Membership from './Components/Pages/Membership';
import Exclusive from './Components/Pages/Exclusive';
import FAQ from './Components/Pages/FAQ';
import Dashboard from './Components/Dashboard/Dashboard';
import ReferralLink from './Components/Pages/ReferralLink';


function Routers() {
    return (
        <Routes>
            {/*Authentication*/}
            {/* <Route path="/Login" element={<Login/>} />
            <Route path="/Signup" element={<Signup />} /> */}

            {/*Layout*/}
            <Route path="/Header" element={<Header />} />
            <Route path="/Footer" element={<Footer />} />

            {/*Pages*/}
            <Route path="/" element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/Membership" element={<Membership/>} />
            <Route path="/Exclusive" element={<Exclusive />} />
            <Route path="/FAQ" element={<FAQ/>} />
            <Route path="/ReferralLink" element={<ReferralLink/>} />

            {/*Dasdboard*/}
            <Route path="/Dashboard" element={<Dashboard />} />


        </Routes>
    );
}

export default Routers;